import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '../tools/withStyles';
import { Link } from '../components/Link';
import { Main } from '../components/Main';
import { Text } from '../components/Text';
import { Fader } from '../components/Fader';
import { Secuence } from '../components/Secuence';
import { Button } from '../components/Button';

const styles = theme => ({
  root: {}
});

class Todl extends React.Component {
  static propTypes = {
    classes: PropTypes.object
  };

  render () {
    const { classes } = this.props;

    return (
      <Main className={classes.root}>
        <article>
          <Secuence stagger>
            <Fader>
              <header>
                <h1><Text>$TODL Airdrop</Text></h1>
              </header>
              <p>
                All Toodle $BAGs were airdropped 15 MIL $TODL tokens each. Check below to claim your tokens or trade $TODL.
              </p>
              <div style={{ margin: [0, 'auto'], padding: 20, width: '656x', textAlign: 'center' }}>
                <a href='https://docs.cryptoodles.com/usdtodl-token/participating-in-the-usdtodl-protocol/redeeming-the-todl-airdrop' >
                  <Button
                  >Claim</Button>
                </a>
                <div style={{ width: '25px', height: 'auto', display: 'inline-block' }}> </div>
                <a href='https://info.uniswap.org/#/tokens/0x98933f2396dbaabf812b6e6d27da4df109c4c55f' >
                  <Button
                  >Trade</Button>
                </a>
              </div>
              <header>
                <h1><Text>The $TODL Token</Text></h1>
              </header>
              <p>
                <Text>
                  $TODL is the official token of the Cryptoodle project. It is a gamified, digital token that is used only in the Cryptoodle Metaverse. <br />
                  <br />Think about it as tokens needed to use an arcade machine. It has no value outside of the Cryptoodles project. <br />
                  <br /> $TODL is a token that gives life to everything inside the Cryptoodle Metaverse. It is used to facilitate trade, rewards, and maintenance of a healthy digital economy within the Cryptoodle Metaverse. <br />
                  <br /> It is now tradeable in Uniswap in both the Ethereum and Polygon Network. View the official $TODL token statistics on <Link href='https://info.uniswap.org/#/tokens/0x98933f2396dbaabf812b6e6d27da4df109c4c55f' target='uniswapTodlStatistics'>Uniswap</Link>. <br />
                </Text>
              </p>
              <header>
                <h1><Text>Tokenomics</Text></h1>
              </header>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <table>
                  <tr>
                    <td>Symbol: </td>
                    <td>TODL</td>
                  </tr>
                  <tr>
                    <td>Opening Price: </td>
                    <td>0.00000003175 ETH (first Uniswap Pool)</td>
                  </tr>
                  <tr>
                    <td>Supply: </td>
                    <td>Around 150 BIL Initial Supply</td>
                  </tr>
                </table>
              </div>
              <header>
                <h1>
                  <Text>Providing Liquidity</Text>
                </h1>
              </header>
              <p>
                Providing liquidity means to back the $TODL token with another, most often with Ethereum, to enable others to buy or sell $TODL.<br />
              </p>
              <p>
                <header>
                  <h1>
                    <Text>Summary</Text>
                  </h1>
                </header>
                The concept of providing liquidity is not new, but the advantages/disadvantages are complex. What liquidity providers for $TODL receive though is an opportunity to be a part of growing Cryptoodles.<br />
                <br />Please research the risks associated with being a liquidity provider before providing liquidity to $TODL.
              </p>
            </Fader>
          </Secuence>
        </article>
      </Main>
    );
  }
}

export default withStyles(styles)(Todl);
